import { NativeDateAdapter } from '@angular/material/core';
import { MatDateFormats } from '@angular/material/core';
import { DateTime } from 'ts-luxon';

const DATE_FORMAT_KEY = 'dateFormat';
/**
 * Returns the number of days between date range.
 */
export function getIntervalBetweenDates(startDate: string, endDate: string): number {
	const startDateWithDayEndTime = new Date(startDate).setHours(23, 59, 59, 999);
	const endDateWithDayEndTime = new Date(endDate).setHours(23, 59, 59, 999);

	// Calculate the difference in milliseconds
	const differenceInMilliseconds = endDateWithDayEndTime - startDateWithDayEndTime;

	// Convert milliseconds to days
	return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
}

/**
 * Retrieves the stored date format from localStorage, or returns a default format
 * if none is found.
 *
 * @param defaultFormat - The default date format to return if no stored format is found (optional).
 * @returns - The stored date format or the default format.
 */
export function getStoredDateFormat(defaultFormat = 'mm/dd/yyyy'): string {
	return localStorage.getItem(DATE_FORMAT_KEY) || defaultFormat;
}

/**
 * Updates the provided date format string by replacing lowercase 'mm' (minutes)
 * with uppercase 'MM' (months) to standardize the format.
 *
 * @param format - The date format string to update.
 * @returns - The updated date format string.
 */
export function updateDateFormat(format: string): string {
	return format.replace(/mm/g, 'MM');
}

/**
 * CustomDateAdapter extends NativeDateAdapter to provide date formatting functionality
 * using the Luxon library. This adapter enables custom date format settings
 * based on user preferences or stored configurations for mat-datepicker.
 */
export class CustomDateAdapter extends NativeDateAdapter {
	constructor() {
		super();
	}

	/**
	 * Formats a given date to a custom string format using Luxon.
	 * Retrieves the stored date format, corrects it if necessary, and
	 * applies it to the provided date using Luxon's formatting capabilities.
	 *
	 * @param date - The date to be formatted; can be a JavaScript Date object,
	 *               a string, or a Luxon DateTime instance.
	 * @returns - A string representing the formatted date according to
	 *            the specified or corrected date format.
	 */
	public override format(date: Date | string | DateTime): string {
		const dateFormat = getStoredDateFormat();
		const updatedDateFormat = updateDateFormat(dateFormat);

		// Attempt to parse the date based on its type
		const luxonDate = DateTime.isDateTime(date)
			? date
			: DateTime.fromISO(date instanceof Date ? date.toISOString() : date);

		return luxonDate.toFormat(updatedDateFormat);
	}
}

/**
 * Returns the number of days between date range.
 */
export function getIntervalBetweenDatesInSeconds(startDate: Date, endDate: Date): number {
	// Get the timestamps in milliseconds
	const timestamp1 = startDate.getTime();
	const timestamp2 = endDate.getTime();

	// Calculate the difference in milliseconds
	const differenceInMilliseconds = timestamp2 - timestamp1;

	// Convert milliseconds to seconds
	return Math.ceil(differenceInMilliseconds / 1000);
}

export function getEpochTime(date: string): number {
	// Parse the date string into a Date object
	const dateObj = new Date(date);

	// Get the epoch time in milliseconds
	const epochTime = Math.floor(dateObj.getTime());
	return epochTime;
}

/**
 * Formats a given date into a human-readable string.
 * @param dateToFormat - The date to format, can be a Date object or a string.
 * @returns A formatted string representation of the date.
 */
export function formatDateToHumanReadableString(dateToFormat: Date | string): string {
	const today = new Date();
	const dateObj = new Date(dateToFormat);

	if (!isNaN(dateObj.getTime())) {
		if (isSameDay(today, dateObj)) {
			return `Today, ${dateObj.toLocaleString([], {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			})}`;
		} else if (isWithinLastWeek(today, dateObj)) {
			return dateObj.toLocaleString('en-US', {
				weekday: 'long',
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			});
		}
	}

	return dateObj.toLocaleDateString('en-US', {
		weekday: 'long',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true
	});
}

// TODO: SN 7/Nov/24 Remove this function in CLP-1106 and consume locale format based on user preference
export function formatIsoString(isoDateString: string): string {
	// Create a Date object from the ISO string
	const date = new Date(isoDateString);

	// Format the date
	const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

	return formattedDate;
}
/**
 * Checks if two dates are on the same day.
 * @param date1 - The first date to compare.
 * @param date2 - The second date to compare.
 * @returns True if the dates are on the same day, false otherwise.
 */
export function isSameDay(date1: Date, date2: Date): boolean {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
}

/**
 * Checks if a given date is within the last week from today.
 * @param today - The current date.
 * @param date - The date to check.
 * @returns True if the date is within the last week, false otherwise.
 */
export function isWithinLastWeek(today: Date, date: Date): boolean {
	const daysInMs = 24 * 60 * 60 * 1000;
	const diffInMs = today.getTime() - date.getTime();
	const diffInDays = Math.abs(Math.floor(diffInMs / daysInMs));
	return diffInDays <= 7;
}

/**
 * Returns a configuration object for custom date formats used by the MatDatePicker.
 * The formats include various representations of date inputs, as well as accessibility labels
 * for month and year selection.
 *
 * @returns - A MatDateFormats object containing the custom date formats and labels.
 */
export function getMatDateFormats(): MatDateFormats {
	return {
		parse: {
			dateInput: 'LL'
		},
		display: {
			dateInput: {
				yyyyMMDD: 'YYYY-MM-DD',
				ddMMYYYY: 'DD/MM/YYYY',
				mmDDYYYY: 'MM-DD-YYYY',
				ddMMYYYYDash: 'DD-MM-YYYY',
				ddMMYYYYDot: 'DD.MM.YYYY',
				mmDDYYYYSlash: 'MM/DD/YYYY',
				yyyyDDMM: 'YYYY.DD.MM',
				yyyyMMDDAlt: 'YYYY.MM.DD'
			},
			monthYearLabel: 'YYYY',
			dateA11yLabel: 'LL',
			monthYearA11yLabel: 'YYYY'
		}
	};
}
