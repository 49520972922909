import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'ts-luxon';

const DATE_FORMAT_KEY = 'dateFormat';

@Pipe({
	name: 'dateFormatter'
})

/**
 * DateFormatterPipe
 * (It format all type of time standards, specially for UTC)
 */
export class DateFormatterPipe implements PipeTransform {
	constructor(private datePipe: DatePipe) {}

	/**
	 * transform date string to any format
	 * @param date - The date to be formatted; can be a JavaScript Date object,
	 *               a string, or a Luxon DateTime instance.
	 * @param format - string represents format to convert date by default value 'MM/dd/yyyy'.
	 * @returns
	 */
	public transform(date: Date | string | DateTime, format = this.getStoredDateFormat(), zone = 'utc'): string {
		if (!date) return '';
		const updatedDateFormat = this.updateDateFormat(format);

		// Dynamically apply the zone only if provided
		const options = zone ? { zone } : {};

		// Attempt to parse the date based on its type
		const luxonDate = DateTime.isDateTime(date)
			? date
			: DateTime.fromISO(date instanceof Date ? date.toISOString() : date, options);

		return luxonDate.toFormat(updatedDateFormat);
	}

	/**
	 * Retrieves the stored date format from localStorage, or returns a default format
	 * if none is found.
	 *
	 * @param defaultFormat - The default date format to return if no stored format is found (optional).
	 * @returns - The stored date format or the default format.
	 */
	public getStoredDateFormat(defaultFormat = 'mm/dd/yyyy'): string {
		return localStorage.getItem(DATE_FORMAT_KEY) || defaultFormat;
	}

	/**
	 * Updates the provided date format string by replacing lowercase 'mm' (minutes)
	 * with uppercase 'MM' (months) to standardize the format.
	 *
	 * @param format - The date format string to update.
	 * @returns - The updated date format string.
	 */
	public updateDateFormat(format: string): string {
		return format.replace(/mm/g, 'MM');
	}
}
