import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { OktaInterfaceService } from './okta-interface.service';

@NgModule({})
export class OktaModule {
	public static forRoot(oktaAuth: OktaAuth, signinRedirectRoute?: string): ModuleWithProviders<OktaModule> {
		return {
			ngModule: OktaModule,
			providers: [
				OktaInterfaceService,
				{
					provide: OKTA_CONFIG,
					useValue: {
						oktaAuth,
						onAuthRequired: async (_oktaAuth: OktaAuth, injector: Injector): Promise<void> => {
							if (signinRedirectRoute) {
								const router = injector.get(Router);
								router.navigate([signinRedirectRoute]);
							} else {
								await oktaAuth.signInWithRedirect();
							}
						}
					}
				}
			]
		};
	}
}
